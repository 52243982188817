import { graphql } from 'gatsby'
import Layout from "../components/layout"
import CardContainer from '../components/Containers/CardContainer/CardContainer'
import Card from "../components/Molecules/Card/Card"
import Highlight from "../components/Molecules/Highlight/Highlight"
import config from '../utils/siteConfig'
import Dropdown from 'react-dropdown';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import 'react-dropdown/style.css';
import React, { Component, useState } from 'react'
import FilterContainer from '../components/Containers/FilterContainer/FilterContainer'
import '../styles/bootstrap.css'
import '../styles/fonts.scss'
import '../styles/components/buttons.css'
import '../styles/global.scss'
import '../styles/components/navbar.css'
import '../styles/components/cards.css'
import AssetLabel from '../components/Atoms/resources/AssetLabel/AssetLabel'
import CategoryLabel from '../components/Atoms/resources/CategoryLabel/CategoryLabel'
import TagLabel from '../components/Atoms/resources/TagLabel/TagLabel'
import ShowMoreBtn from '../components/Atoms/resources/ShowMoreBtn/ShowMoreBtn'
import AllResourceLoadedBtn from '../components/Atoms/resources/AllResourceLoadedBtn/AllResourceLoadedBtn'

import Button from "../components/Atoms/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry } from "@fortawesome/free-solid-svg-icons"
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";


class Blog extends Component {
  constructor(props) {
    super(props)
    debugger;
    // arrays
    this.optionsCategoryTypes = props.data.allContentfulCategory.distinct
    if (!props.data.allContentfulCategory.distinct.some(value => (value.title === 'All'))) {
      this.optionsCategoryTypes.unshift('All')
    }

    debugger;
    // combining all the content
    this.blogs = props.data.allContentfulBlog.edges
    this.news = props.data.allContentfulNews.edges
    this.page = props.data.allContentfulZBlog.edges[0].node

    this.resources = this.blogs.concat(this.news)


    this.resourceCount = config.resourcesInitialShowCount;
    this.LoadMore = true;
    if (this.resourceCount >= this.resources.length) {
      this.LoadMore = false;
    }

    this.highlights = this.blogs.concat(this.news)

    this.state = {
      selected: '',
      isResult: true,
      loadMore: this.LoadMore,
      totalResources: this.resources.length,
      categoryType: this.optionsCategoryTypes[0]
    }

    this._onSelectCategory = this._onSelectCategory.bind(this)
    this._onLoadMoreResources = this._onLoadMoreResources.bind(this)

    this.defaultOptionCategoryTypes = this.optionsCategoryTypes[0];

    this.allData = []

    this.options = {
      renderNode: {
        [BLOCKS.HEADING_1]: (node, children) => (
          <h1 className="heading1">{children}</h1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 className="heading2">{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h3 className="heading3">{children}</h3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
          <h4 className="heading4">{children}</h4>
        ),
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
          <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
        ),
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <p className="aboutParagraph">{children}</p>
        ),
        [BLOCKS.UL_LIST]: (node, children) => {
          return (
            <ul
              style={{
                listStyle: 'disc',
                lineHeight: '35px',
                marginLeft: '1rem',
              }}
            >
              {children.map((item) => (
                <li key={item.key}>{item.props.children[0].props.children[0]}</li>
              ))}
            </ul>
          );
        },
        //[BLOCKS.UL_LIST]: (node, children) => (
        //    <span className="d-inline-block bulletSpan">{children}</span>
        //),

      },
      renderText: (text) =>
        text
          .replace(/\u2028/g, "")
          .split("\n")
          .flatMap((text, i) => [i > 0 && <br />, text])
    }

  }



  _onLoadMoreResources() {
    debugger;
    this.setState({ loadMore: false })
    this.resourceCount = this.resources.length
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterCategoryTypes)
    this.checkFilterResultsCount()
  }

  _onSelectCategory(option) {
    console.log('You selected ', option)
    this.setState({ categoryType: option })

    var filterCategoryTypes = option

    this.checkFilterResults(filterCategoryTypes)
    this.checkFilterResultsCount()
  }

  sortFilterResults() {
    console.log('You selected ', this.allData[0].node.publishDate)
    this.allData.sort(function (date1, date2) {
      date1 = new Date(date1.node.publishDate);
      date2 = new Date(date2.node.publishDate);
      if (date1 > date2) return -1;
      if (date1 < date2) return 1;
    })
  }

  componentDidMount(prevProps, prevState) {
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterCategoryTypes)
    this.sortFilterResults()
    this.checkFilterResultsCount()

    this.setState({ categoryType: this.defaultOptionCategoryTypes })
    // this.aos = AOS;
    // AOS.init({});
  }



  checkFilterResults(filterCategoryTypes) {
    debugger;

    if (filterCategoryTypes === "All") {
      this.allData = this.resources;
    } else {
      this.allData = this.resources.filter(function (_resource) {
        return (filterCategoryTypes != "All" ? _resource.node.categories.some(value => (value.title === filterCategoryTypes || value.slug === filterCategoryTypes)) : true);
      });
    }

    this.allData = this.allData.slice(0, this.resourceCount)

  }

  checkFilterResultsCount() {
    if (this.allData.length > 0) {
      this.setState({ isResult: true })
      this.sortFilterResults()
    } else {
      this.setState({ isResult: false })
    }
  }




  render() {

    return (
      <Layout
        IsHeaderImageDark={false}
        navColour={"white"}>
        <head>
          <title>{this.page.metaData.title}</title>
          <meta charset="utf-8" />
          <meta name="description" content={this.page.metaData.description} />
          <meta name="keywords" content={this.page.metaData.keywords.keywords} />
          <link rel="alternate" hreflang="de" href="https://wunderloop.io/de/blog" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en/blog" />
        </head>


        {/*Header section*/}
        <section className="bg-white">
          <div className="sectionFit-container3 bg-white">
            <div className="row pt-4">
              <h1 className="align-top h1-small h1-purple pl-2">
                {this.page.bgHHd}
              </h1>
            </div>
          </div>
        </section>


        {/*tabs*/}
        <div className="sectionFit-container3 bg-white">
          <Tabs
            defaultActiveKey={this.defaultOptionCategoryTypes}
            id="uncontrolled-tab-example"
            onSelect={this._onSelectCategory}
            className="mb-3 d-flex flex-row flex-nowrap">
            {this.optionsCategoryTypes.map((tabTitle, index) => (
              <Tab
                eventKey={tabTitle}
                title={tabTitle}
              >
                <CardContainer>
                  {this.allData.map((resource, index) => (
                    <Card key={resource.node.id} {...this.page} {...resource.node} featured />
                  ))}
                </CardContainer>
              </Tab>
            ))}
          </Tabs>
        </div>

        {/*recent highlights*/}
        <section className="bg-white">
          <div className="head-container bg-white">
            <div className="head-canvas bg-white">
              <div className="row pt-4">
                <h1 className="align-top h1-mediumSmall h1-darkBlue pl-2 pb-4">
                  Recent Highlights
                </h1>
              </div>
                <div className="d-flex flex-row flex-nowrap">
                  {this.highlights.map((highlight, index) => (
                    <Highlight key={highlight.node.id} index={index} {...this.page} {...highlight.node} featured />
                  ))}
                </div>
            </div>
          </div>
        </section>

        {/*footer*/}
        <section className="bg-white">
          <div className="sectionFit-container2 bg-white">
            <div className="row text-white pt-5 align-items-center pb-5">
              <div className="col-lg-6 pl-0 pr-5 pb-5">
                <h1 className="align-top h1-medium2 h1-darkBlue">
                  {this.page.bgFHd}
                </h1>
                <p className="p-regular text-darkgray3 pt-1 pb-3">
                  {this.page.bgFTxt}
                </p>
                <Button
                  className='btns'
                  buttonStyle='btn--outlinePurple2'
                  buttonSize='btn--large3'
                  href={config.navigationURL_register}
                >
                  {this.page.bgFBtn}{" "}
                  <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                </Button>
                <h2 className="h2-medium text-darkgray pt-0">
                  {documentToReactComponents(this.page.bgFRtxt.json, this.options)}
                </h2>
              </div>
              <div className="col-lg-3">

              </div>
              <div className="col-lg-3">
                <h1 className="align-top h3-small h1-darkBlue">
                  {this.page.bgFHd2}
                </h1>
                <p className="p-regular4 text-darkgray3 pt-1 pb-1">
                  {this.page.bgFTxt2}
                </p>
                <Button
                  className='btns'
                  buttonStyle='btn--primaryPurple'
                  buttonSize='btn--txt'
                  href={config.navigationURL_register}
                >
                  {this.page.bgFBtn2}{" "}
                  <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                </Button>
              </div>
            </div>
          </div>
        </section>

      </Layout >
    )
  }
}

export default Blog




export const query = graphql`
query($locale: String!) {
  allContentfulTag(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: title, order: ASC }
  ) {
    distinct(field: title)
    nodes {
      slug
      title
      id
    }
  }
  allContentfulZBlog(
    filter: {
    node_locale: { eq: $locale }
  }) {
    edges {
      node {
        id
        metaData {
          slug
          title
          description
          keywords {
            keywords
          }
        }
          bgFBtn
        bgFBtn2
        bgFHd
        bgFHd2
        bgFRtxt {
          json
        }
        bgFTxt
        bgFTxt2
        bgHBtn
        bgHBtn2
        bgHHd
        
      }
    }
  }
  allContentfulCategory(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: title, order: ASC }
  ) {
    distinct(field: title)
    nodes {
      slug
      title
      id
    }
  }
  allContentfulNews(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: [publishDate], order: DESC })
  {
    edges {
      node {
        id
        contentType
        metaData {
          title
          slug
        }
        headerImage {
          file {
            url
            fileName
          }
        }
        publishDate(formatString: "MMMM DD, YYYY")
        categories {
          id
          slug
          title
        }
        tags {
          id
          slug
          title
        }
        headline {
          headline
        }
        abstract {
          abstract
        }
        previewImage {
          file {
            url
            fileName
          }
        }
        publisher
        sourceLink
      }
    }
  }
  allContentfulBlog(
    filter: {
    node_locale: { eq: $locale }
  },
    sort: { fields: [publishDate], order: DESC }
  ) {
    edges {
      node {
        id
        contentType
        metaData {
          title
          slug
        }
        headerImage {
          file {
            url
            fileName
          }
        }
        publishDate(formatString: "MMMM DD, YYYY")
        categories {
          id
          slug
          title
        }
        tags {
          id
          slug
          title
        }
        headline {
          headline
        }
        abstract {
          abstract
        }
        previewImage {
          file {
            url
            fileName
          }
        }
        publisher
      }
    }
  }
}
`
